import React, { useEffect } from 'react';
import fallBackLogo from '../../images/logo2.png';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const { orgName } = useParams();

  // const Logo =
  //   JSON.parse(localStorage.getItem("org"))?.horizontalLogo || fallBackLogo;
  const Logo = fallBackLogo;

  return (
    <>
      <footer className="py-8 bg-gray-100 absolute w-full bottom-0">
        <div className="container mx-auto px-5 sm:px-0">
          <div className="flex  items-center gap-3 flex-wrap md:flex-nowrap justify-center md:justify-between">
            <p className="text-base text-base-content/80">
              © 2025 TalentSwap Inc. All Right Reserved.
            </p>
            <div className="flex items-center gap-6">
              {/* {location.pathname.includes(`${orgName}/questions/`) ||
              location.pathname.includes(`${orgName}/preassesment/`) ||
              location.pathname.includes(`/critical-thinking-assessment`) ? (
                <div className="text-base text-base-content/60 hover:opacity-60 transition-all cursor-pointer">
                  Terms of Service
                </div>
              ) : (
                <a
                  className="text-base text-base-content/60 hover:opacity-60 transition-all"
                  href="/"
                >
                  Terms of Service
                </a>
              )} */}

              {location.pathname.includes(`${orgName}/questions/`) ||
              location.pathname.includes(`${orgName}/preassesment/`) ||
              location.pathname.includes(`${orgName}/aptitude/`) ||
              location.pathname.includes(`${orgName}/Disc-Assesment`) ||
              location.pathname.includes('/critical-thinking-assessment') ||
              location.pathname.includes('/motivators-questionnaire') ? (
                <div className="mx-auto md:mx-0 cursor-pointer">
                  <img src={Logo} className="h-9" />
                </div>
              ) : (
                <a className="mx-auto md:mx-0" href={`/${orgName}`}>
                  <img src={Logo} className="h-9" />
                </a>
              )}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
